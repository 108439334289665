import './App.css';
import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import GoogleSignInButton from './components/GoogleSignInButton';
import { FaCheckCircle } from "react-icons/fa";
import { FaTimes } from 'react-icons/fa';
import { FaCircleXmark } from "react-icons/fa6";

function App() {
    const [ user, setUser ] = useState([null]);
    const [profile, setProfile] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const PointView = React.lazy(() => import('./app/Point'));
    const [loginSuccess, setLoginSuccess] = useState(false);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
      () => {
        if (user !== null) {
          axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: 'application/json'
              }
            })
            .then((res) => {
              setProfile(res.data);
              setShowPopup(true);
              // ポップアップを一定時間後に非表示にする
              setTimeout(() => setShowPopup(false), 10000);
            }).catch(error => {
              if (error.response && error.response.status === 401) {
                setProfile(null);
              }
            });
        }
      },
      [ user ]
    );

    useEffect(() => {
      if (profile !== null) {
        if (profile.email.endsWith('otani.ed.jp')) {
          setLoginSuccess(true);
        } else {
          setLoginSuccess(false);
          logOut();
        }
      }
    }, [profile]);

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
    };

    return (
      <div className="App">
        <header className="App-header">
          {showPopup && (
            <div className="popup big-Font">
              <FaTimes className="close-icon" onClick={() => setShowPopup(false)} />
              {loginSuccess ? (
                <h3><FaCheckCircle color="green" /> ログイン完了</h3>
              ) : (
                <h3><FaCircleXmark color="red" /> 403 認証失敗</h3>
              )}
              <p><img src={profile.picture} alt="user image" /></p>
              <p>Name: {profile.name}</p>
              <p>Email: {profile.email}</p>
            </div>
          )}
          <div>
            {loginSuccess ? (
                <div>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <PointView />
                  </React.Suspense>
                </div>
            ) : (
              <div className='big-Font'>
                <h2>O-CAST 2024 オンライン得点板 v2.0.0b2r</h2>
                <img src="o-cast_3d_a.png" alt="O-CAST 2024" className='App-BigLogo'></img>
                <p className='to-caption'>ログインしてください</p>
              <GoogleSignInButton onClick={login} />
              </div>
            )}
          </div>
        </header>
      </div>
    );
}
export default App;